<template>
  <section class="positive-house">
    <section class="hero">
      <div class="hero-container">
        <h1>La RE 2020 est opérationnelle depuis 2021</h1>
      </div>
    </section>
    <div class="hero-img">
      <img src="@/assets/img/pages/positive-house-1.jpg" alt="La maison positive arrive en 2021" />
    </div>
    <article class="grid-container article">
      <div class="article-title fit-content">
        <h3>
          Une nouvelle réglementation thermique va bientôt s’appliquer aux constructions
          résidentielles. Quels sont les changements à prévoir pour les futurs propriétaires de
          maison ?
        </h3>
      </div>
      <div class="article-card">
        <card-type-img>
          <template v-slot:content>
            <p>
              Si le calendrier est encore à prendre avec précaution, les contours purement
              techniques de la RT 2020 – ou RE 2020 pour Réglementation Environnementale –
              commencent, eux, à se préciser. A quelques mois de son entrée en vigueur, on en sait
              beaucoup plus sur les véritables enjeux de cette norme à laquelle
              <router-link :to="{ name: 'project-house-construction' }"
                >tout constructeur de maison à Vannes</router-link
              >
              (Morbihan), Angers (Maine-et-Loire) ou La Rochelle (Charente-Maritime) devra se
              conformer, comme partout en France.
            </p>
            <p>
              Parce que rien ne part jamais de rien en matière législative, signalons que les
              nouvelles prescriptions thermiques à mettre en œuvre succèdent à un autre référentiel
              introduit en 2013 dans les textes officiels sous le nom de RT 2012, toujours valide à
              ce jour. A partir du 1er janvier prochain (une date encore indicative), la RE 2020,
              qui conserve les grands principes généraux de sa devancière et en partagent les mêmes
              objectifs initiaux tout en les dépassant, prendra le relais -c’est en fait déjà le cas
              depuis 2018 pour les bâtiments publics -. Afin de mesurer l’impact opérationnel et
              budgétaire de ces directives, il convient au préalable d’en cerner la nature et de
              comprendre les évolutions concrètes qu’elles impliquent.
            </p>
          </template>
          <template v-slot:img>
            <img src="@/assets/img/pages/positive-house-2.jpg" alt="RE 2020" />
          </template>
        </card-type-img>
      </div>
      <div class="article-construction-cost fit-content">
        <h2>Maîtriser les coûts de construction</h2>
        <div class="article-construction-cost-img">
          <img
            src="@/assets/img/pages/positive-house-3.jpg"
            alt="Maîtriser les coûts de construction"
          />
        </div>
        <div class="article-construction-cost-text">
          <p>
            Tout d’abord, la RE 2020 intègre dans ses prérequis le concept de construction à énergie
            positive : tous les bâtiments qui répondent à ce critère sont capables de produire, à
            partir de sources renouvelables et naturelles (panneaux solaires, solutions géothermiques,
            ventilation, cuves enterrées pour récupérer et stocker les apports hydriques) plus de
            chaleur, d’eau ou d’électricité qu’ils n’en consomment. Les normes, plus strictes,
            prennent aussi en compte les performances globales de la maison liées à l’efficacité de
            son isolation, l’optimisation de sa conception bioclimatique (orientation plein sud), de
            sa structure (toits, murs, fenêtres), jusqu’à l’empreinte carbone des matériaux employés
            dans sa construction.
          </p>
          <p>
            Nous ne nous risquerons pas, ici, à fournir des indices précis, une phase de simulation,
            conduite par l’Etat, étant toujours en cours pour fixer des niveaux de consommation
            réglementaires : mais l’objectif ambitieux de la RE 2020 est de diviser par deux – voire
            trois – les dépenses d’énergies primaires générées par ces nouveaux habitats et de réduire
            « <i>significativement</i> » leurs émissions de carbone. C’est aussi dans cette logique que le
            ministère de la transition Ecologie et Solidaire entend vouloir encourager « <i>une
            transformation progressive des techniques du BTP, des filières industrielles et des
            solutions énergétiques</i> » afin de maîtriser le
            <router-link to="/projet-construction-maison/financement">coût de construction d’une maison</router-link>.
          </p>
          <p>
            Certains professionnels du secteur font déjà preuve d’innovation pour proposer des prix
            plus compétitifs : cette stratégie, qui passe notamment par la rationalisation de leur
            fonctionnement et de leur modèle, vise notamment à établir en circuit-court un lien direct
            avec la clientèle (absence d’intermédiaire et de commerciaux) et à limiter leur frais de
            structure, de marketing et de publicité.
          </p>
          <p>
            Après une phase de concertation qui sera menée printemps 2020 pour « analyser les effets
            de la nouvelle norme RE 2020 sur les matériaux utilisés, les modes constructifs et les
            différents métiers du bâtiment », la publication des textes réglementaires définitifs
            devrait être effective au cours du quatrième trimestre, annoncent les services de l’Etat.
          </p>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
import CardTypeImg from '@/components/general/CardTypeImg.vue';

export default {
  name: 'positive-house',
  components: {
    CardTypeImg,
  },
};
</script>

<style lang="sass">
.positive-house
  background-color: $medium-bg
  padding-bottom: 6rem
  .hero
    background-color: darken($primary, 5%)
    height: 35rem
    display: flex
    flex-direction: column
    align-items: center
    &-container
      margin-top: 8rem
      width: 60rem
      h1
        color: $white
        text-align: center
    &-img
      display: flex
      flex-direction: row
      justify-content: center
      img
        border-radius: $global-border-radius
        margin-top: -15rem
  .article
    &-title
      display: flex
      justify-content: center
      margin: 4.5rem auto 0 auto
      h3
        text-align: center
    &-card
      margin: 4.5rem auto 0 auto
      p:not(:last-child)
        margin-bottom: 1rem
      a
        font-weight: normal !important
    &-construction-cost
      margin: 4.5rem auto 0 auto
      h2
        text-align: center
        color: $primary
      &-img
        margin-top: 4.5rem
        img
          object-fit: cover
          width: 100%
          border-radius: $global-border-radius
      &-text
        margin-top: 3rem
        p:not(:last-child)
          margin-bottom: 1rem
        a
          @include hyperlink
          text-decoration: none
          font-size: inherit
          font-weight: normal
  .fit-content
    width: 90%;
    max-width: 950px;
  @media (max-width: 768px)
    .hero
      height: 20rem
      &-container
        margin-top: 6rem
        width: 90%
      &-img
        img
          object-fit: cover
          width: 90%
          margin-top: -5rem
    .article
      &-title
        margin: 2.5rem auto 0 auto
      &-card
        margin: 2.5rem auto 0 auto
      &-construction-cost
        margin: 3rem auto 0 auto
        &-img
          margin-top: 2.5rem
        &-text
          margin-top: 1.5rem
</style>
